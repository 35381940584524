import { motion, AnimatePresence } from "framer-motion";
import { useState } from "react";
import { FaChevronDown } from "react-icons/fa";

export default function DropDown({ setType, type, types , setIsViewAllActive , status}) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  function handleDropDownItemClick(newType) {
  //  newType == 'View All' ?setIsViewAllActive(status):setType(newType);
  //   setIsDropdownOpen(false);
  if( newType == 'View All'){
    setIsViewAllActive(status)
    // setType(newType);
  }else{
    setIsViewAllActive("")
    setType(newType);
  }
  }
  return (
    <>
      <motion.div
        className="dropDown headBtn"
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
      >
        <span>{type}</span>
        <FaChevronDown size={10} />
      </motion.div>
      <AnimatePresence mode="popLayout">
        {isDropdownOpen && (
          <motion.div
            className="dropdownPanel"
            initial={{
              y: 0,
            }}
            animate={{
              y: 50,
            }}
            exit={{
              y: 0,
              opacity: 0,
            }}
            transition={{
              duration: 1,
              ease: "backInOut",
            }}
          >
            {types.map((type) => (
              <div
                value={type}
                key={type}
                className="dropdownItem"
                onClick={() => handleDropDownItemClick(type)}
              >
                {type}
              </div>
            ))}
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
}
