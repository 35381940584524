import React, { useContext, useEffect, useState } from "react";
// import AuthCard from "../../Component/Authentication/AuthCard";
import Footer from "../../Component/Footer/Footer";
import logo from "../../assets/images/navbar/CTcLogo1.png";
import "./SignIn.css";
import email from "../../assets/images/Auth/Solid/Communication/email.png";
import user from "../../assets/images/Auth/Solid/Communication/user.png";
import phone from "../../assets/images/Auth/Solid/Communication/Phone.png";
import lincess from "../../assets/images/Auth/Solid/Devices/licenss.png";
import { AppContext } from "../../Context/Context";
import NavBar from "../../Component/NavBar/NavBar";
import { Type, User } from "lucide-react";
import UserDetails from "../../Component/Authentication/UserDetails";
import { AuthCard } from "../../Component/Authentication/AuthCard";

const SignIn = () => {
  const login = [
    { image: email, placeholder: "Email", type: "email", name: "email" },
  ];
  const signup = [
    { image: user, placeholder: "First Name", type: "text", name: "fname" },
    { image: user, placeholder: "Last Name", type: "text", name: "lname" },
    { image: email, placeholder: "Email", type: "email", name: "email" },
    { image : email , placeholder : 'DOB' , type : 'date' , name : 'DOB'},
    // {
    //   image: lincess,
    //   placeholder: "Lichess ID",
    //   type: "text",
    //   name: "lichessId",
    // },
    {
      image: phone,
      placeholder: "Mobile Number",
      type: "phone",
      name: "phone",
    },
  ];

  const loginAccount = {
    name: "login",
    button: {notLoading:"Log In",loading:"Logging In..."},
    heading: "Sign In",
    googleButton: "Log in with Google",
    text: "New? Sign Up - and Contest to Win!",
  };
  const signUpAccount = {
    name: "signup",
    button: {notLoading:"Sign Up",loading:"Signing Up..."},
    heading: "Sign Up",
    googleButton: "Continue with Google",
    text: "Already have an Account?  Sign In",
  };
  const [account, setAccount] = useState("login");
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const { googleSignIn } = useContext(AppContext);

  useEffect(() => {
    function handleResize() {
      const width = window.innerWidth;
      setIsMobile(width < 768);
      //   setIsTablet(width >= 768 && width < 1024);
      //   setIsDesktop(width >= 1024);
    }

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <div className="signInContainer">
      {!isMobile ? (
        <div className="headingContainer">
          <img src={logo} alt="Logo" />
          <h2>Chess Tycoon</h2>
        </div>
      ) : (
        <NavBar />
      )}
      <div
        className="authCardContainer"
        style={{
          minHeight: `${
            isMobile ? "calc(90vh - 120px)" : "calc(85vh - 120px)"
          }`,
        }}
      >
        {!googleSignIn ? <AuthCard
          account={account}
          setAccount={setAccount}
          formInput={account === "login" ? login : signup}
          accountType={account === "login" ? loginAccount : signUpAccount}
        /> : <UserDetails />
      }
      </div>
      <Footer />
    </div>
  );
};

export default SignIn;
